const Logos = {
  copyright_myfeldt_logo: require("../assets/images/homePage/My_Feldt_Copyright.svg").default,
  snallkalendern_logo: require("../assets/images/homePage/snall-logo.svg").default,
  snallkalendern_logo_green: require("../assets/images/homePage/snällkalendern_logo_green.svg").default,
  snallkalendern_logo_white: require("../assets/images/2024/Snällkalendern_logo_white.svg").default,
  friends_logo: require("../assets/images/homePage/friends_logo.svg").default,
  friends_logo_yellow: require("../assets/images/2024/friends_logo_light_yellow.svg").default,
  tietoevry_logo: require("../assets/images/homePage/tietoevry_logo.svg").default,
  daniel_nordberg_logo: require("../assets/images/2024/Daniel_Norberg_signature.svg").default,
};

export default Logos;
